// -------------------------------------------------
// LOGIN
// -------------------------------------------------

$pe-7s-font-path: "~pe7-icon/dist/fonts";
@import "~pe7-icon/dist/scss/pe-icon-7-stroke.scss";
@import url("https://cdn.syncfusion.com/ej2/20.3.56/bootstrap5.css");

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0 !important;
  margin: 0 !important;
}

.layout-container {
  .slider {
    background-color: #fff;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  }

  .header {
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    padding: 0 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .trigger {
      font-size: 1.25rem;
    }

    .user-dropdown {
      display: flex;
      align-items: center;
      padding: 0 0.75rem;
      transition: 0.5s;

      .username {
        font-size: 0.75rem;
        margin-right: 0.5rem;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
        @media only screen and(max-width: 426px) {
          font-size: 0.6rem;
          max-width: 80px;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }

  .content {
    margin: 1rem 1rem 0 1rem;
    @media only screen and(max-width: 426px) {
      margin: 1.5rem;
    }
  }
}

.helpMenu {
  .anticon {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-card-body {
  padding: 10px 24px !important;
}

.ant-card {
  border-radius: 10px !important;
}

.navigation {
  .menu-logo {
    padding: 0.75rem 1.25rem;
    text-align: center;
    background-color: #fafbfc;
    img {
      height: 40px;
    }
  }
  .menu-heading {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-top: 1rem;
    padding: 1rem 1.25rem;
    font-weight: bold;
    color: #0080c7;
  }
  .menu-item {
    display: flex;
    align-items: center;
    i {
      text-align: center;
      width: 34px;
      height: 34px;
      line-height: 34px;
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}

.button-action {
  height: 40px !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
}

.app-page-title {
  background-color: rgba(255, 255, 255, 0.45);
  padding: 2rem;
  //  margin: -2rem;
  @media only screen and(max-width: 769px) {
    text-align: center;
  }
  .page-title-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media only screen and(max-width: 769px) {
      flex-direction: column;
      justify-content: center;
    }

    .page-title-actions {
      @media only screen and(max-width: 769px) {
        margin: 15px auto 0;
      }
    }

    .page-title-heading {
      font-size: 1.25rem;
      font-weight: 400;
      // display: flex;
      // align-items: center;
      // justify-content: center;

      .page-title-subheading {
        padding: 3px 0 0;
        font-size: 0.88rem;
        opacity: 0.6;
      }

      h1 {
        font-size: 23px;
      }

      .page-title-icon {
        font-size: 2rem;
        display: flex;
        align-items: center;
        align-content: center;
        text-align: center;
        padding: 0.83rem;
        margin: 0 30px 0 0;
        background: #fff;
        box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03),
          0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),
          0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
        border-radius: 0.25rem;
        width: 60px;
        height: 60px;
        @media only screen and(max-width: 769px) {
          display: none;
        }

        i {
          margin: auto;
        }
      }
      .page-title-icon.image {
        padding: 0;
      }
    }
  }
}

.page-container {
  margin-top: 1rem;
}

.create-content {
  max-width: 1000px;
  margin: 1rem auto;
}

.dashboard-page {
  display: grid;
  row-gap: 1rem;

  .row1 {
    .stats {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;

      .stats-block {
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        .data {
          // margin-top: .5rem;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;

          .icon {
            margin-right: 1rem;
          }

          .count {
            font-size: 2.5rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    @media only screen and(max-width: 769px) {
      grid-template-columns: 1fr;
    }
  }
}

.login-page {
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%) !important;

  height: 100vh;

  .container {
    display: flex;
    align-items: center;
    height: 100vh !important;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto 0 auto;

    .content {
      background-color: #fff;
      width: 100%;
      border-radius: 0.25rem;
      box-shadow: 0 0.76875rem 2.4875rem rgba(52, 58, 64, 0.3),
        0 1.3375rem 1.70625rem rgba(52, 58, 64, 0.3), 0 0.55rem 0.53125rem rgba(0, 0, 0, 0.05),
        0 0.225rem 0.4375rem rgba(52, 58, 64, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 2rem;
      margin: 2rem;
      max-width: 500px;

      .body {
        text-align: center;

        .heading {
          font-size: 1.75rem;
          color: #a1a1a1;
        }

        .subheading {
          font-size: 1rem;
        }
      }

      button {
        // height: 3rem;
        font-size: 0.88rem;
      }
    }

    .copyline {
      color: #dbdbdb;
    }
  }
}

.import-content-form {
  .form {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    column-gap: 10px;
    align-items: center;

    .label {
      text-align: right;
    }

    .field {
      width: 100%;
    }

    .btn {
      text-align: left;
    }
  }
}

// helpers
.icon-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-emphasis-color: transparent;
}

.bg-happy-itmeo {
  background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%) !important;
}

//FT
// Goup import content component
.group-import-content {
  .group-import-content-col1 {
    text-align: center;
    border: 2px solid black;
    .gic-top-col1 {
      // border-bottom: 1px solid black;
      width: 100%;
      margin-bottom: 20px;
      height: auto;
    }
  }
  .group-import-content-col2 {
    text-align: center;
    border: 2px solid black;
    .gic-top-col2 {
      margin-bottom: 20px;
      width: 100%;
      height: auto;
      // border-bottom: 1px solid black;
    }
  }
}

.gic-publish-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  .group-import-content-pub-btn {
    text-align: center;
    margin-right: 10%;
  }
  .group-import-content-schedule-pub-btn {
    text-align: center;
    margin-left: 15%;
  }
}

.logo-img-div {
  position: relative;
  width: 150px;
  // height: 150px;
  // border: black 2px solid;
  // height: auto;
  margin-bottom: 10px;
  .logo-img {
    width: auto;
    height: 90px;
  }
  .photo-div {
    // position: relative;
    // display: flex;
    // // justify-content: center;
    // height: 80px;
    // margin-bottom: 3%;
    // border: 2px solid black !important;
    .logo-img-upload {
      .photo {
        width: 100%;
        // position: absolute;
        // top: 105%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // -ms-transform: translate(-50%, -50%);
        color: #1890ff;
        border: none;
        cursor: pointer;
        border-radius: 2px;
        opacity: 0;
        height: 0;
        display: none;
      }
      Button {
        width: 100%;
        margin-top: 10px;
        // position: absolute;
        // top: 105%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // -ms-transform: translate(-50%, -50%);
        background-color: #1890ff;
        border: none;
        cursor: pointer;
        border-radius: 2px;
      }
    }
  }
}

.hr-group-edit {
  overflow: visible; /* For IE */
  height: 30px;
  border-style: solid;
  border-color: black;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}

.option-bar-5 {
  // width: 17%;
  border: none;
  // border: 2px solid yel low;
  // height: 50px;
  position: relative;
  // top: -100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .import-btn {
    // border: 2px solid red;
    // position: absolute;
    // top: 0px;
    height: 60%;
    width: 100%;
  }
  .import {
    // border: 2px solid blue;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    // right: -20%;
    opacity: 0;
  }
}

.btn-grp-content {
  Button {
    margin-right: 10px;
  }
}

.clickable-table td {
  cursor: pointer;
}

.rdw-editor-main {
  height: 500px !important;
  overflow: auto;
  border: 1px solid rgb(204, 204, 204);
}

.title-actions {
  display: flex;
  flex-direction: column;

  @media only screen and(max-width: 769px) {
    .ant-btn-group {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.login-container {
  background: #fff;
  height: 100vh;

  .logo {
    display: inline-block;
    top: 50px;
    left: 50px;
    right: initial;
    bottom: initial;
    z-index: 10;
    position: absolute;
  }

  p {
    font-weight: 300;
    margin-top: 10px;
  }

  @media (min-height: 700px) {
    .logo {
      position: fixed;
    }
  }

  img {
    margin-bottom: 2px;
  }

  .img-holder {
    width: 40%;
    max-width: 550px;
    overflow: hidden;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%) !important;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    border: 1px solid rgba(255, 255, 255, 0.125);
    display: none;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 991px) {
    .img-holder {
      display: flex;
    }
  }

  .main-content {
    display: flex;
    height: 100%;
  }

  .form-holder-second {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-holder {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      flex: 1;
      width: 50%;
    }
  }

  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-sidebar {
    background: #613ead;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../public/ankara.png");
    background-position: 100% 40%;
    @media only screen and(max-width: 800px) {
      display: none;
    }

    & > div {
      background: linear-gradient(
        149.45deg,
        rgba(255, 255, 255, 0.34) 52.52%,
        rgba(255, 255, 255, 0.34) 109.58%
      );
      backdrop-filter: blur(12px);
      max-width: 489px;
      position: relative;
      border-radius: 30px;
      width: 100%;
      color: white;
      padding-top: 3rem;
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .socials {
        margin: 0;
        position: absolute;
        right: -1rem;
        top: -1rem;

        & > div {
          border-radius: 9999px;
          background-color: white;
          margin-bottom: 10px;
          padding: 12px;
          max-width: 46px;
        }
      }

      img {
        // height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
      }

      & > div {
        width: 100%;
        max-height: 100%;
        flex: 1;
      }

      & > h1 {
        font-size: 32px;
        color: white;
        max-width: 350px;
        font-weight: 700;
        margin-right: 2rem;
      }
    }
  }
  .form-content {
    max-width: 420px;
    flex: 1;
    padding: 0 1rem;
  }

  .form-items {
    h3 {
      color: black;
    }

    input,
    .ant-input-password {
      border: none;
      background-color: #f7f7f7;
      border-radius: 6px;
      padding: 12px;
      font-weight: 300;
      //
    }

    input[type="text"]:hover,
    input[type="text"]:active,
    input[type="text"]:focus {
      border: none;
      outline: 0;
    }

    .ant-form-item-explain-error {
      margin-bottom: 6px;
      margin-top: 3px;
    }

    .ant-input-password:hover,
    .ant-input-password:active,
    .ant-input-password:focus {
      border: none;
      outline: 0;
    }

    button {
      background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
      color: #fff;
      box-shadow: 0 0 0 #c5fcdc;
      border-radius: 6px;
      border: 0;
      padding: 10px;
      font-weight: 700;
      text-decoration: none;
      cursor: pointer;
      outline: none;
      width: 100%;
      height: auto;
    }

    button:hover {
      opacity: 0.9;
    }

    .checkbox {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      input {
        margin-bottom: 0;
      }
      label {
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 300;
      }
    }
  }
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #efeff0 !important;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 23px;
    margin-right: 30px !important;
  }
}

.login-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.custom-password {
  padding: 0 !important;
  padding-right: 8px !important;
  input {
    padding: 12px !important;
  }
}

.search-input {
  input {
    border-radius: 5px;
  }

  button {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.domain-modal {
  h5 {
    margin-top: 20px !important;
  }

  table {
    margin: 10px 0 !important;
  }

  p {
    margin: 10px 0 !important;
  }

  li {
  }
}

.test {
  position: relative;
  > div {
    padding: 1.5rem;
  }
  .ant-message-custom-content {
    display: flex;
    position: relative;
  }

  li {
    text-align: left;
  }

  p {
    text-align: left;
    margin-bottom: 0.3rem;
  }

  .anticon-close-circle {
    display: none;
  }

  svg {
    position: absolute;
    top: -1rem;
    right: -1rem;
    color: red;
  }
}

.ant-collapse-borderless {
  background-color: #fff !important;
}

.ant-mycollapse {
  background-color: #f7f7f7;
  border-radius: 6px;
  padding: 0 12px;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 0 !important;
}

.ant-input-group-addon {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.ant-collapse-borderless > .ant-collapse-item {
  border: none !important;
}

.divider {
  border-top: 1px solid #d9dadc;
  display: block;
  line-height: 1px;
  margin-top: 30px;
  margin-bottom: 18px;
  position: relative;
  text-align: center;
}

.divider-title {
  background: #fff;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 0 20px;
  text-transform: uppercase;
}

.ant-mycollapse {
  background: #b6178e;
  color: white;

  svg {
    fill: white;
  }
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 5px;
}

.loading-card {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

// .ant-table-filter-trigger-container {
//   left: 0 !important;
//   right: auto !important;
// }

// .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
//   padding: 8px 8px 8px 2.3em !important;
// }

// .ant-dropdown-placement-bottomRight {
//   left: auto !important;
// }

// .ant-table-selection-column {
//   width: 40px !important;
//   min-width: 30px !important;
// }

.tags-column .ant-table-filter-trigger-container,
.role-column .ant-table-filter-trigger-container {
  left: 0;
  width: 20px;
}

.tags-column .ant-table-filter-column-title,
.role-column .ant-table-filter-column-title {
  padding-left: 25px !important;
}

.notification-message {
  white-space: pre-wrap;
}

.doc-modal {
  width: 100% !important;
  max-width: 1000px !important;
  top: 20px !important;
}

.link-button {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  text-align: left;
}

.pg-viewer-wrapper {
  flex: 1;
  width: 100%;
}

.pdf-canvas {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h-100 {
  height: 100%;
}

.meeting-card {
  max-width: 480px;
  max-width: 10px auto;

  p {
    margin-bottom: 0;
  }
}

.editor-title-action-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  div{
    display: flex;
    gap: 10px
  }
}

.editing-user-modal-button{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}



.settings-page .page-header .ant-btn-group{
  margin-bottom: 0 !important;
}


.settings-page .page-header h1{
  padding: 7px 0 !important;
}